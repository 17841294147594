const g = (e) => /^#[0-9a-fA-F]{3}$/.test(e) || /^#[0-9a-fA-F]{6}$/.test(e), d = (e) => {
  if (!g(e))
    return console.error(`유효하지 않은 색상 표기법입니다: ${e}`), [
      0,
      0,
      0
    ];
  let t = e.slice(1);
  t.length === 3 && (t = t.split("").reduce((a, c) => a + c + c, ""));
  const s = t.slice(0, 2), r = t.slice(2, 4), n = t.slice(4), i = parseInt(s, 16), l = parseInt(r, 16), o = parseInt(n, 16);
  return [
    i,
    l,
    o
  ];
}, u = (e, t = 1) => {
  const [s, r, n] = d(e);
  return `rgba(${s}, ${r}, ${n}, ${t})`;
};
export {
  u as r
};
